@media print {
  /* @page {
     margin: auto;
    break-before:avoid 
  } */
}


.InfpCompoPrint {
  height: 70mm;
  background: #fafafa;
  text-transform: uppercase;
  font-family: 'Asap Condensed', sans-serif;
  width: 40mm;
}

.InfpCompoPrint .infoText {
  writing-mode: vertical-rl;
  margin: 0mm auto 0mm;
  height: 67mm;
  font-family: 'Georgia', sans-serif;
  text-align: right;
}
.InfpCompoPrint .infoTex:first-child{
  text-align: center;
}
.InfpCompoPrint .infoText p{
  margin: 0.5mm;
  font-family: Garamond;
  color: black;
}

.InfpCompoPrint .divImg{
  height: 3px;
  width: 13mm;
  padding-right: -22px;
  margin-right: -40px;
  padding-bottom: 26mm;
}
.InfpCompoPrint .info  {
  height: 28mm;
    width: 38mm;
    margin: auto;
    font-size: 14px;
    text-align: center;
}

.InfpCompoPrint .ID {
  font-family: system-ui;
  font-variant: proportional-width;
  margin: 15px 0 5px;
}

.InfpCompoPrint .divImg .img {
  width: auto;
  height: 40px;
  margin-top: -9px;
  transform: rotate(89deg);
}
.InfpCompoPrint .logo {
  text-align: center;
}

.InfpCompoPrint .logo h6 {
  font-size: inherit;
}