.Register {
	margin:0;
	color:#6a6f8c;
	background:#c8c8c8;
    font:600 16px/18px 'Open Sans',sans-serif;
    text-align: center;
}
.Register *,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}

.button{
    margin-top: 30px;
}
.Register .login-wrap{
	width:100%;
	margin:auto;
	max-width:525px;
	min-height: 100vh;
	/* min-height:670px; */
	position:relative;
    background-image: url('/yadByad.png');
	background-repeat: repeat;
    background-size: 138px 104px;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.Register .login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding:60px 70px 50px 70px;
	background:rgba(40,57,101,.9);
}
.Register .login-html .sign-in-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	transform:rotateY(180deg);
	backface-visibility:hidden;
	transition:all .4s linear;
}
.Register .login-html .sign-in,
.Register .login-form .groupLogin .check{
	display:none;
}
.Register .login-html .tab,
.Register .login-form .groupLogin .label,
.Register .login-form .groupLogin .button{
	text-transform:uppercase;
}
.Register .login-html .tab{
	font-size:35px;
	margin-right:15px;
	padding-bottom:5px;
	margin:0 0px 24px 0;
	display:inline-block;
}
.Register .login-html .sign-in:checked + .tab{
	color:#fff;
	border-color:#1161ee;
}
.Register .login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.Register .login-form .groupLogin{
	margin-bottom:15px;
}
.Register .login-form .groupLogin .label,
.Register .login-form .groupLogin .input,
.Register .login-form .groupLogin .button{
	width:100%;
	color:#fff;
	display:block;
}
.Register .login-form .groupLogin .input,
.Register .login-form .groupLogin .button{
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:rgba(255,255,255,.1);
}
.Register .login-form .groupLogin input[data-type="password"]{
	/* text-security:circle; */
	-webkit-text-security:circle;
}
.Register .login-form .groupLogin .label{
	color:#aaa;
	font-size:12px;
}
.Register .login-form .groupLogin .button{
	background:#1161ee;
}
.Register .login-form .groupLogin label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.Register .login-form .groupLogin label .icon:before,
.Register .login-form .groupLogin label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.Register .login-form .groupLogin label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.Register .login-form .groupLogin label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.Register .login-form .groupLogin .check:checked + label{
	color:#fff;
}
.Register .login-form .groupLogin .check:checked + label .icon{
	background:#1161ee;
}
.Register .login-form .groupLogin .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.Register .login-form .groupLogin .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.Register .login-html  .sign-in-htm{
	transform:rotate(0);
}


.Register .hr{
	height:2px;
	margin:60px 0 50px 0;
	background:rgba(255,255,255,.2);
}
.Register .foot-lnk{
	text-align:center;
}
.Register .select {
	border: none;
	padding: 11px 18px;
	border-radius: 25px;
	background: rgba(255,255,255,.1);
}

.Register .MuiInputLabel-formControl{
    top: -3px;
    left: -46px;
    right: 0;
}

/* .Register .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input{ */
	/* text-align: center;
    padding-right: 5px;
    color: white; */
/* } */
.Register label + .MuiInput-formControl{
	margin-top: 0;margin-right: 9px;
}
.Register label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated{
/* text-align: center; */
/* left: 172px; */
color: white;
}

/* .Register label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled
{
	/* transform-origin: top center;
	top: -15px; */
/* } */ 
.Register input{
	text-align: center;
}