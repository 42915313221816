@media print {
  @page {
     /* height: 90mm; */
     /* width:  29mm; */
     margin: auto;
    break-before:avoid 
  }
}


.ComponentToPrint {
  height: 70mm;
  /* width:  auto; */
  /* height: 90mm;
  width:  29mm; */
  /* background: #fafafa;
  display: flex;
  text-transform: uppercase;
  font-family: 'Asap Condensed', sans-serif;
  padding: 10px;
  margin: 10px; */
  background: #fafafa;
  /* display: flex; */
  text-transform: uppercase;
  font-family: 'Asap Condensed', sans-serif;
  /* padding: 10px; */
  /* margin: 10px; */
  /* width: 62mm; */
  width: 40mm;

  /* transform: rotate(90deg); */
}

.ComponentToPrint .divImg{
height: 4px;
}
.ComponentToPrint .info  {
  height: 28mm;
    width: 38mm;
    margin: auto;
    font-size: 14px;
    text-align: center;
}

.ComponentToPrint .ID {
  font-family: system-ui;
  font-variant: proportional-width;
  margin: 15px 0 5px;
}

.ComponentToPrint .divImg .img {
  width: auto;
  height: 40px;
  margin-top: -9px;
}
.ComponentToPrint .logo {
  text-align: center;
  /* width: 355px; */
  /* height: 520px; */
  /* border: black 2px dotted; */
}

.ComponentToPrint .logo h6 {
  font-size: inherit;
}