.Family * {
    box-sizing: border-box;
    /* font-family: Arial, Helvetica, sans-serif; */
    direction: rtl;
  }
  
  /* .Family #title {
      text-align: center;
      font-family: arial, sans-serif;
    } */
    .Family{
      margin-top: 5px;
    }
    .Family #AutocompleteOrigin .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd{
      padding-right: 2px;
      margin-top: 0;
      font-size: 17px;
     }
     .Family #AutocompleteOrigin .MuiInputBase-input {
       margin: -4px 0 0 0;
       text-align: center;
     }

     .Family label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
      transform: none;
    }
    .Family #AutocompleteOrigin .MuiAutocomplete-endAdornment {
       position: relative;
     }
     .Family label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
       display: none;
     }
     .Family .MuiInputLabel-formControl{
       top:3px; right: 45px;
     }
    .Family .group{
      position: relative;
      display: inline-block;
    }

    .Family  .tablePersons {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      border: 3px solid #ddd; 
      width: fit-content; 
      margin: auto;
      display: block;
      max-height: 120px;
      overflow: auto;
    }
    .Family  .tablePersons tr th {
      position: sticky;
      top: 0;
    }
    
    .Family  .tablePersons td
    /* , .tablePersons th */
     {
      border: 1px solid #ddd;
      padding: 3px;
      /* padding: 0; */
    }
    
    .Family .tablePersons tr:nth-child(even){background-color: #f2f2f2;}
    
    .Family .tablePersons tr:hover {background-color: #ddd;}
    
    .Family .tablePersons th {
      /* padding-top: 12px; */
      /* padding-bottom: 12px; */
      border: 1px solid #ddd;
      text-align: center;
      background-color:darkgrey;
      color: white;
    }
  
  
  /* .DatePicker{margin-left: 15px;} */
  .Family .DatePicker svg{
      padding:0 0 0 0;
      font-size: 1.2rem;
  }
  .Family .DatePicker button{padding:0;} 
  .Family .DatePicker input{padding:3px; text-align: center; font-size: 17px;} 
  .Family .DatePicker{display: inline-block; }
  .Family .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd{
    height: 24px;
  }
  
   /* .Family.Div1{
      margin-top: 10px;
      width: fit-content;
      border: 2px solid rgb(132, 207, 230);
      text-align: center;
      padding: 5px 20px 5px 5px;
  } */
  .Family ::placeholder {
      color:rgba(180, 181, 184, 0.856);
    }
  
    .Family .inputFam:focus{
        border-bottom: solid 1.5px blue;
    }
    .Family .inputFam:hover{
      border-bottom: solid 2px black;
  }
  
  