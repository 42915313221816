.Alert .backgroundAlert { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: rgba(0,0,0, 0.5);
  } 

  .Alert .backgroundAlert #Alert {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    padding: 5%;
    border-radius: 20px;
    background: white;
    text-align: center;
  }

  /* .backgroundAlert #Alert i {
      float: left;
  } */
  .Alert .Alertred{
      background-color:rgb(242, 130, 136)!important
  }

  .Alert .Alertgreen {
    background-color:rgb(98, 235, 168)!important
  }

  .Alert .Alertorange{
    background-color: rgb(255, 244, 106)!important
}
  .Alert button{
    margin-top: 25px;
  }

  .Alert #H1{
    display: inline-block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }