.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 40%;
  padding: 5%;
  margin-bottom: 10%;
  border-radius: 20px;
  background: white;
  text-align: center;
}
.btn1{
float: right;
}
.btn2{
float: left;
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  background-color: rgb(52, 158, 200)!important;
  padding: 5%;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 0;
}

.popup_inner h2 {
  margin-bottom: 4rem;
}