.GeneralPopup .backgroundPop { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  } 
  .GeneralPopup .Pop .btn1{
    float: right;
    }
    .GeneralPopup .Pop  .btn2{
    float: left;
    }
  .GeneralPopup .Pop {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    background-color: rgb(52, 158, 200)!important;
    padding: 5%;
    border-radius: 20px;
    text-align: center;
  }

  .GeneralPopup h2 {
    margin-bottom: 4rem;
  }