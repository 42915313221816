body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .App { margin-top: 25px;
  text-align: center;
}
.App a{margin-left: 15px; 
 padding-top:7px;
 padding-bottom:7px;
 padding-left:7px ;
 padding-right:7px ;
background-color: limegreen;
width: 150px;
border-color: rgb(34, 235, 34);
box-shadow: 2px 2px cadetblue;
text-decoration: none;
box-sizing: border-box;
color: darkslategray;
} 
.App a:hover{
  box-shadow: 5px 5px cadetblue;
  background-color: rgb(255, 251, 0);
}
.App a:active{
  background-color:orangered;
  box-shadow: none;
} */





* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
/* font {display: none;} */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(240 240 240)!important;
}

input {
  background-color: rgb(240 240 240);
}
.topnavAPP , .topnavAPPLogout{
  overflow: hidden;
  background-color: cadetblue;
  z-index: 1;  position: fixed;
  margin-bottom: 5px;
}
.topnavAPP{
  width: 60px;
  height: 60px;
  border-radius: 0 0 0 100px;
  -moz-border-radius: 0 0 0 100px;
  -webkit-border-radius: 0 0 0 100px;
}
.topnavAPPLogout {left: 0;
  width: 60px;
  height: 60px;
  /* background: red; */
  border-radius: 0 0 100px 0;
  -moz-border-radius: 0 0 100px 0;
  -webkit-border-radius: 0 0 100px 0;
}

 .topnavAPPLogout a{
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 17px 28px;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.topnavAPP a {
  display: block;
  color: #f2f2f2;
  padding: 19px 14px;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.topnavAPP :hover , .topnavAPPLogout :hover{
  background-color: seagreen;
  color: black; 
}

#user{
  float: right;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.content {
  background-color: #ddd;
  padding: 10px;
 text-align: right;

}

.footer {
  background-color: #f1f1f1;
  padding: 10px;
  text-align: right;
}
.dov{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.App .Fullscreen {
  left: -175px;
  position: fixed;
  width: 186px;
  z-index: 10;
  display: flex;
  flex-direction: row-reverse;
}

.App .Fullscreen:hover{
  left: 0px;
} 
#Persons{
  width: 94%;
  margin: auto;
}
 /* .Persons button:hover i{
    font-size: x-large;
 } */
#title {
    text-align: center;
    font-family: arial, sans-serif;
  }
.Persons .center {
  display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
}
  
.Persons .styleButton {
display: none;
}

.Persons .MuiFormControl-root{
  height: 55px;
}
.Persons .Little {
  display: none;
  }

@media screen and (max-width: 992px) {
  .Persons .BigS {
  display: none;
  }
  .Persons .Little {
    display: block;
    }
  .Persons .styleButton {
    display: -webkit-inline-box;
    margin-right: 25px;
  }
  .Persons .top {
    padding: 85px 0 15px;
  }
  .Persons .DivSearch {
    flex-wrap: wrap;
    /* width: 64%; */
    /* width: 100%; */
    margin: auto;
  }
  .Persons .margin1 {
    flex-wrap: wrap;
    height: auto!important;
    padding-bottom: 20px!important;
    justify-content: space-evenly!important;
  }
  .Persons .margin2 {
    flex-wrap: wrap;
    height: auto!important;
    padding-bottom: 20px!important;
    justify-content: space-evenly!important;
  }
  .Persons .checkedDiv {
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
  }
  .Persons .group {
    margin-top: 15px;
  }


}
  
  #persons {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    display: block;
    max-height: 600px;
    overflow: auto;
    margin: auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  #persons tr th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  #persons td, #persons th {
    border: 1px solid #ddd;
    padding: 4px;
  }
  
  #persons tr:nth-child(even){background-color: darkseagreen;}
  
  #Excel{
    padding: 3px 10px;
    font-size: 0.8125rem;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
margin-right: 20px;
  }
  #persons tr:hover {background-color: aqua;}
  
  #persons th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: darkgray;
    color: white;

  }

  .Persons .margin1{
    /* margin-bottom: 5px; */
    height: 51px;
    padding-bottom: 8px;
    border-bottom: grey 1px solid;
    background-color: #f2f2f2;
    /* padding-left: 3px; */
    /* padding-right: 7px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Persons   .margin1 input{
    background-color: #f2f2f2;
  }
  .Persons  .margin2{
    /* padding-top: 8px; */
    height: 51px;
    padding-bottom: 8px;
    border-bottom: grey 1px solid;
    background-color: #e7eaed;
    /* padding-left: 3px; */
    /* padding-right: 7px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Persons  .margin2 input {
    background-color: #e7eaed;
  }
  .Persons   .marginchecked{
    height: auto;
    background-color: #f2f2f2;
}

.Persons .checkedDiv {
  padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


  .Persons #AutocompleteCity input{
    background-color: transparent;
  }

.Persons .DateOfUpdate input{
  background-color: transparent;
}
.Persons #table{
    float: left;
    min-width: 64%;
    overflow: auto;    
    margin-top: 20px;
  }

  .Persons .tbody{
    height: 680px;
    overflow: auto;

  }

  .Persons .DivSearch{
    /* width: 34%;
    min-width: 471px;
    display: inline-block;
    border: 2px solid rgb(231, 228, 228);
    outline: rgb(146, 144, 144) solid 1px;
    outline-offset: -17px;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #f2f2f2; */

        /* height: 597px; */
    /* width: 39%; */
    /* min-width: 471px; */
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: center;
    /* justify-content: center; */
    /* margin-top: 20px; */
    /* border: 2px solid rgb(231, 228, 228); */
    outline: rgb(146, 144, 144) solid 1px;
    /* outline-offset: -17px; */
    text-align: center;
    /* padding-right: 20px; */
    /* padding-left: 20px; */
    /* background-color: #f2f2f2;*/
   } 

  .Persons .top{
    padding: 5px 0 15px;
    display: flex;
    justify-content: space-evenly;
}
  
.Persons  .groupPersons{
    position: relative;
    /* margin-bottom: -55px; */
    display: inline-block;
    /* margin: 5px; */
    /* margin-left: 3px;
    margin-right: 4px; */
  }

  .Persons .group {
    position: relative;
    /* margin-bottom: -55px; */
    display: inline-block;
    /* margin: 5px; */
    /* margin-left: 5px; */
    margin-right: 3px;
    width: 145px;
  }


/* body{background-image: url('https://cdn.pixabay.com/photo/2015/12/16/16/18/child-1096177__340.png'); */
/* background-size: 300px;} */

.Home .imgbackground{
    /* background-image:url(process.env.PUBLIC_URL + '../public/yadByad.png ') */
    background-image: url('/yadByad.png');
    background-size: cover;
    background-size: 69px;
    width: -webkit-fill-available;
    position: absolute;
    height: -webkit-fill-available;
    opacity: 0.30;
}

.Home .topHome {
    right: 0;    
    left: 0; 
    color: white;
    max-width: 15rem;
    position: fixed;
    height: 4rem;
    background-color: darkblue;
    border-radius: 24px;
    margin: auto;
    top: 0;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;   
}
/* .Home .topimgbackground {
    right: 0;
    left: 0;
    width: 99rem;
    max-width: 80%;
    position: fixed;
    height: 70px;
    margin: auto;
    opacity: 0.30;
    background-image: url('/yadByad.png');
    background-image: url(https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png);
    background-size: cover;
    background-position: unset;
    background-size: 69px;
    top: 0;
} */
/* .Home:not(#buttons)  {
    opacity: 0.60;
} */
    /* position:absolute ; */
    /* background-color: burlywood; */
     /* height: 100%;width: 75vw; */
     /* left: 0; background-image: url("https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png");} */
.Home .buttons {
    /* position: relative; */
    margin: auto;
    width: 22rem;
    padding-top: 110px;
    /* margin: auto; */
    /* max-width: 100%; */
    /* height: 182px; */
}

.Home .buttonAddPerson {
    /* background-color: rgb(35, 40, 86); */
    background-color:  rgb(22,74,104);

    margin-bottom: 30px;
    width: -webkit-fill-available;
    line-height: 2.75;
    font-size: large;
    /* justify-content: right; */
 }

.Home .buttonPersons {
    background-color:  rgb(43,150,211);
    /* background-color:  rgb(11,37,52); */
    margin-bottom: 30px;
    width: -webkit-fill-available;
    line-height: 2.75;
    font-size: large;

}

.Home .topnavAPP {
    display: none;
}
.NotFound{
    margin: 7% auto 0;
    max-width: 390px;
    min-height: 180px;
    padding: 30px 0 15px;
}
.AddPerson {
  box-sizing: inherit;
  bottom: 0;
  right: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: 1.4286;
}

.AddPerson .b {
  width: 28rem;
  max-width: 100%;
  box-sizing: inherit;
  transition: .2s;
  border-radius: 8px;
  border: 1px solid #dadce0;
  margin: 130px auto 0;
  position: relative;
  height: auto;
  min-height: 380px;
  box-sizing: inherit;
  overflow-y: auto;
  transition: .2s;
  padding: 48px 40px 36px;
  flex-grow: 1;
  overflow: hidden;
  -webkit-box-flex: 1;
  background-color: #fff;
}

.AddPerson .c {
  box-sizing: inherit;
}

.AddPerson .icon {
  height: 24px;
  box-sizing: inherit;

}

.AddPerson .d {
  margin: auto -40px;
  padding-right: 40px;
  padding-left: 40px;
  overflow: hidden;
  box-sizing: inherit;

}

.AddPerson .e {
  box-sizing: inherit;
  text-align: center;
}

.AddPerson h1 {
  color: #202124;
  padding-bottom: 0;
  padding-top: 16px;
  font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3333;
  margin-bottom: 0;
  margin-top: 0;
  box-sizing: inherit;
}

.AddPerson .f {
  color: #202124;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .1px;
  line-height: 1.5;
  padding-bottom: 0;
  padding-top: 8px;
  margin-bottom: 0;
  margin-top: 0;
  box-sizing: inherit;
}

.AddPerson .f span {
  color: #202124;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .1px;
  line-height: 1.5;
  padding-bottom: 0;
  padding-top: 8px;
  text-align: center;
  font-family: roboto,'Noto Sans Myanmar UI',arial,sans-serif;
}

.AddPerson .g {
  display: inline-block;
  font-size: 14px;
  padding: 40px 0 0;
  vertical-align: top;
  white-space: normal;
  width: 100%;
  box-sizing: inherit;
  border-right-width: 40px;
  border-left-width: 40px;
  text-align: center;
}

.AddPerson  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled{
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  z-index: 0
}

.AddPerson .p {
  align-items: flex-start;
  display: flex;
  box-flex: 0;
  flex-grow: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 63px;
  min-height: 48px;
  padding-bottom: 20px;
}

.AddPerson .butadd{
  background-color: #1a73e8;
}

.AddPerson .butcon{
 background-color:brown;
}
.GeneralPopup .backgroundPop { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  } 
  .GeneralPopup .Pop .btn1{
    float: right;
    }
    .GeneralPopup .Pop  .btn2{
    float: left;
    }
  .GeneralPopup .Pop {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    background-color: rgb(52, 158, 200)!important;
    padding: 5%;
    border-radius: 20px;
    text-align: center;
  }

  .GeneralPopup h2 {
    margin-bottom: 4rem;
  }
.Alert .backgroundAlert { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: rgba(0,0,0, 0.5);
  } 

  .Alert .backgroundAlert #Alert {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    padding: 5%;
    border-radius: 20px;
    background: white;
    text-align: center;
  }

  /* .backgroundAlert #Alert i {
      float: left;
  } */
  .Alert .Alertred{
      background-color:rgb(242, 130, 136)!important
  }

  .Alert .Alertgreen {
    background-color:rgb(98, 235, 168)!important
  }

  .Alert .Alertorange{
    background-color: rgb(255, 244, 106)!important
}
  .Alert button{
    margin-top: 25px;
  }

  .Alert #H1{
    display: inline-block;
    font-size: 2em;
    -webkit-margin-before: 0.67em;
            margin-block-start: 0.67em;
    -webkit-margin-after: 0.67em;
            margin-block-end: 0.67em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
  }
body{
  /* background-color: crimson; */
  direction: rtl;
}
/* .MuiFormControl-roo{
vertical-align: sub;
} */
.Update{
  /* background-color: green; */
  width: 100%;
  /* margin: auto; */
  margin-bottom: 1000px;
} 
.Update .UpdateBeneath {
  max-width: 950px;
    margin: auto;
}
.Update .from {
  max-width: 950px;
  margin: auto;
}

.Update .Updatetop {
  text-align: center;
  padding: 5px 0 15px;
  display: flex;
  justify-content: space-evenly;
}

.Update .Updatetop h3{
  margin-top: 5px;
  margin-bottom: 0;
}
.Update textarea {
  background-color: inherit;
  resize: none;
  width: 90%;
  /* border: navajowhite; */
  min-height: 60px;
  margin: auto;
}
#textarea{
  outline: none;
  min-height: 65px;
}

.Update .borderText {
    /* padding: 4px; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin: 3px 0px -6px;
    /* margin-right: 15px; */
    background: rgb(255, 255, 255);
    /* margin-bottom: 12px; */
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    /* margin: 0px; */
}
.Update .Divlabelcheckbox {
  margin-top: -10px;
  min-height: 40px;
  /* width: 818.5px; */
  border: 2px solid rgb(231, 228, 228);
  outline: rgb(146, 144, 144) solid 1px;
  outline-offset: -5px;
  /* text-align: center; */
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: initial;
  place-items: center;
  text-align: center;
  background-color: lightgrey;
}
.Update .Divlabel {
  margin-top: -10px;
  min-height: 95px;
  /* width: 818.5px; */
  border: 2px solid rgb(231, 228, 228);
  outline: rgb(146, 144, 144) solid 1px;
  outline-offset: -17px;
  /* text-align: center; */
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  place-items: center;
}

.Update .MuiFormControl-root {
  min-width:-webkit-fill-available;
}
.Update .MuiInputLabel-formControl {
  top: -6px;
}
.Update label + .MuiInput-formControl {
  margin-top: 10px;
}
.Update .group 			  { 
  position: relative;
  /* margin-bottom: -55px; */
  display: inline-block;
  /* margin: 5px; */
  margin-left: 5px;
  margin-right: 5px;
  width: 145px;
  } 



/* .Update .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
text-align: right;
padding-right: 5px;
}
.Update .MuiInputLabel-formControl{
right: 0; 
}
.Update .MuiInputLabel-root{text-align: right; padding-left: 24px;}

.Update .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth{
vertical-align: sub;
}  */

/* .Update #MaritalStatus svg.MuiSvgIcon-root.MuiSelect-icon{
right: 14px;
}
.Update #NoOfPersons svg.MuiSvgIcon-root.MuiSelect-icon{
right: 12px;
}
.Update #residence svg.MuiSvgIcon-root.MuiSelect-icon{
right: -30px;
}
.Update #Population svg.MuiSvgIcon-root.MuiSelect-icon{
right: -40px;
}
.Update label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated{
color:#999; 
font-size:18px;
} */
/* .Update label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled{
transform-origin: top right;
}
.Update label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated{
transform-origin: top right;

}
.Update .MuiSelect-icon{
margin-right: 97px;  
}
.Update button.MuiButtonBase-root.MuiIconButton-root {
padding: 0;
} */


/* .Update #AutocompleteStreet .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd{
padding-right: 2px;
}
.Update #AutocompleteStreet .MuiAutocomplete-endAdornment {
 position: relative;
}
.Update #AutocompleteCity .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd{
padding-right: 2px;
}
.Update #AutocompleteCity .MuiAutocomplete-endAdornment {
position: relative;
} */

img{width: 400px; height: 350px;}

#checkboxDiv{height: 111px; display: inline-block;}

.family{
 width: 120px;
}
.family~.bar{
  width: 120px;
}


.open , .upd{
padding: 3px;
font-size: 15px;
}
.open{
display: inline-block;
}
.upd{
 float: left;
}

.Update .left {
  display: inline-block;
}

.LABEL1{
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  right: 5px;
  top: -10px;
  font-size: 14px;
  color: #5264AE;
}

.Update .botm{
  /* width: 1190px; */
  /* height: 264px; */
  max-width: 950px;
  margin: 0 auto 100px;
}
.Update-right {
margin: auto;
/* width: 818px; */
}



.labelCheckbox{
  color:#999; 
  font-size:18px;
}

.Update .Family .tablePersons{
  width: auto;
  max-height: -webkit-fill-available;
}
.Update .Family .group{
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.Update #beneathImg{
/* height: 172px; */
text-align: center;
background-color: rgb(172, 248, 172);
overflow: auto;
border: 2px solid gray;
/* width: 818px; */
margin: auto;
}

#Holidays {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#Holidays td, #Holidays th {
  border: 1px solid #ddd;
  padding: 8px;
}

.dates{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  } 
  .dates .num {
  font-size: 12px;
  }

#Holidays tr:nth-child(even){background-color: #f2f2f2;}

#Holidays tr:hover {background-color: #ddd;}

#Holidays th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
}


/* form starting stylings ------------------------------- */

input 				{
font-size:18px;
padding:10px 10px 10px 5px;
display:inline-block;
width:-webkit-fill-available;
border:none;
border-bottom:1px solid #757575;
}
input:focus 		{ outline:none;}

/* LABEL ======================================= */
.LABEL 				 {
color:#999; 
font-size:18px;
font-weight:normal;
position:absolute;
pointer-events:none;
right:5px;
top:10px;
transition:0.2s ease all; 
-moz-transition:0.2s ease all; 
-webkit-transition:0.2s ease all;
}

/* active state */
input:focus ~ label, input:valid ~ label 		{
top:-10px;
font-size:14px;
color:#5264AE;
}

/* BOTTOM BARS ================================= */
/* .bar 	{ 
position:relative; display:block; width:145px; }
.bar:before, .bar:after 	{
content:'';
height:2px; 
width:0;
bottom:1px; 
position:absolute;
background:#5264AE; 
transition:0.2s ease all; 
-moz-transition:0.2s ease all; 
-webkit-transition:0.2s ease all;
}
.bar:before {
left:50%;
}
.bar:after {
right:50%; 
}

/* active state */
/* input:focus ~ .bar:before, input:focus ~ .bar:after {
width:50%;
} */ */

/* HIGHLIGHTER ================================== */
/* .highlight {
position:absolute;
height:60%; 
width:100px; 
top:25%; 
left:0;
pointer-events:none;
opacity:0.5;
}

/* active state */
/* input:focus ~ .highlight {

-webkit-animation:inputHighlighter 0.3s ease;
-moz-animation:inputHighlighter 0.3s ease;
animation:inputHighlighter 0.3s ease;
} */ */

/* ANIMATIONS ================
@-webkit-keyframes inputHighlighter {
from { background:#5264AE; }
to 	{ width:0; background:transparent; 
}
}
@-moz-keyframes inputHighlighter {
from { background:#5264AE; }
to 	{ width:0; background:transparent; 
}
}
@keyframes inputHighlighter {
from { background:#5264AE; }
to 	{ width:0; background:transparent; 
}
} */

/* button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-containedSizeSmall.MuiButton-sizeSmall{
position: unset;
} */

.LoadToServer{
  overflow: auto;
  text-align: center;
  margin-bottom: 100px;
  border: 2px solid gray;
  height: 172px;
  width: 818px;
  margin: auto;
  padding: 0px 20px;
}
.Loadinput{
font-size: 14px;
  padding: 7px;
  display: inline-block;
  width: auto;
  border: none;
  margin-left: 10px;
}

.Loadinput1{
font-size: 14px;
  padding: 2px;
  display: inline-block;
  width: auto;
  border: solid black 1px;
  margin-left: 10px;
}

.Update .topnav {
  overflow: hidden;
  background-color: teal;
  /* padding: 0; */
  /* width: 818px; */
  margin: auto;
  padding-right: 25%;

}
.Update .topnav li {
  float: right;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.Update .topnav li:last-child:hover {
  background-color: teal;
}

.Update .topnav li:hover {
  background-color: #ddd;
  color: black; 
}

.Update .Family {
  border: 2px gray solid
}

.Update .WebcamCapture {
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Update .file  {
  margin-right: 30px;
  margin-top: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}


.topnav li{
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .Update .Updatetop {
    padding: 85px 0 15px;
  }
  .Update .dates {
    justify-content: center;
  }
  .Update .from {
    text-align: center;
  }
  .Update .group {
    position: relative;
    width: 200px;
    margin: 16px auto 0;
    display: block;
}
.Update .Divlabel {
  margin-top: 3px;
  min-height: 95px;
  /* width: 818.5px; */
  border: 2px solid rgb(231, 228, 228);
  outline: rgb(146, 144, 144) solid 1px;
  outline-offset: -17px;
  /* text-align: center; */
  padding: 30px 0;
  display: block;
}
.Update .Divlabelcheckbox {
  margin-top: 3px;
  min-height: 95px;
  /* width: 818.5px; */
  border: 2px solid rgb(231, 228, 228);
  outline: rgb(146, 144, 144) solid 1px;
  outline-offset: -17px;
  /* text-align: center; */
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
}

}

.WebcamCapture .Webcam , .WebcamCapture .imgWebcam{ width:300px; height:250px; }
.WebcamCapture .buttonWebcam { width:300px; }
.Family * {
    box-sizing: border-box;
    /* font-family: Arial, Helvetica, sans-serif; */
    direction: rtl;
  }
  
  /* .Family #title {
      text-align: center;
      font-family: arial, sans-serif;
    } */
    .Family{
      margin-top: 5px;
    }
    .Family #AutocompleteOrigin .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd{
      padding-right: 2px;
      margin-top: 0;
      font-size: 17px;
     }
     .Family #AutocompleteOrigin .MuiInputBase-input {
       margin: -4px 0 0 0;
       text-align: center;
     }

     .Family label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
      transform: none;
    }
    .Family #AutocompleteOrigin .MuiAutocomplete-endAdornment {
       position: relative;
     }
     .Family label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
       display: none;
     }
     .Family .MuiInputLabel-formControl{
       top:3px; right: 45px;
     }
    .Family .group{
      position: relative;
      display: inline-block;
    }

    .Family  .tablePersons {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      border: 3px solid #ddd; 
      width: -webkit-fit-content; 
      width: -moz-fit-content; 
      width: fit-content; 
      margin: auto;
      display: block;
      max-height: 120px;
      overflow: auto;
    }
    .Family  .tablePersons tr th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
    
    .Family  .tablePersons td
    /* , .tablePersons th */
     {
      border: 1px solid #ddd;
      padding: 3px;
      /* padding: 0; */
    }
    
    .Family .tablePersons tr:nth-child(even){background-color: #f2f2f2;}
    
    .Family .tablePersons tr:hover {background-color: #ddd;}
    
    .Family .tablePersons th {
      /* padding-top: 12px; */
      /* padding-bottom: 12px; */
      border: 1px solid #ddd;
      text-align: center;
      background-color:darkgrey;
      color: white;
    }
  
  
  /* .DatePicker{margin-left: 15px;} */
  .Family .DatePicker svg{
      padding:0 0 0 0;
      font-size: 1.2rem;
  }
  .Family .DatePicker button{padding:0;} 
  .Family .DatePicker input{padding:3px; text-align: center; font-size: 17px;} 
  .Family .DatePicker{display: inline-block; }
  .Family .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd{
    height: 24px;
  }
  
   /* .Family.Div1{
      margin-top: 10px;
      width: fit-content;
      border: 2px solid rgb(132, 207, 230);
      text-align: center;
      padding: 5px 20px 5px 5px;
  } */
  .Family ::-webkit-input-placeholder {
      color:rgba(180, 181, 184, 0.856);
    }
  .Family ::-ms-input-placeholder {
      color:rgba(180, 181, 184, 0.856);
    }
  .Family ::placeholder {
      color:rgba(180, 181, 184, 0.856);
    }
  
    .Family .inputFam:focus{
        border-bottom: solid 1.5px blue;
    }
    .Family .inputFam:hover{
      border-bottom: solid 2px black;
  }
  
  
@media print {
  @page {
     /* height: 90mm; */
     /* width:  29mm; */
     margin: auto;
    page-break-before:avoid;
    -webkit-column-break-before:avoid;
            break-before:avoid 
  }
}


.ComponentToPrint {
  height: 70mm;
  /* width:  auto; */
  /* height: 90mm;
  width:  29mm; */
  /* background: #fafafa;
  display: flex;
  text-transform: uppercase;
  font-family: 'Asap Condensed', sans-serif;
  padding: 10px;
  margin: 10px; */
  background: #fafafa;
  /* display: flex; */
  text-transform: uppercase;
  font-family: 'Asap Condensed', sans-serif;
  /* padding: 10px; */
  /* margin: 10px; */
  /* width: 62mm; */
  width: 40mm;

  /* transform: rotate(90deg); */
}

.ComponentToPrint .divImg{
height: 4px;
}
.ComponentToPrint .info  {
  height: 28mm;
    width: 38mm;
    margin: auto;
    font-size: 14px;
    text-align: center;
}

.ComponentToPrint .ID {
  font-family: system-ui;
  font-feature-settings: ;
  font-variant: proportional-width;
  margin: 15px 0 5px;
}

.ComponentToPrint .divImg .img {
  width: auto;
  height: 40px;
  margin-top: -9px;
}
.ComponentToPrint .logo {
  text-align: center;
  /* width: 355px; */
  /* height: 520px; */
  /* border: black 2px dotted; */
}

.ComponentToPrint .logo h6 {
  font-size: inherit;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 40%;
  padding: 5%;
  margin-bottom: 10%;
  border-radius: 20px;
  background: white;
  text-align: center;
}
.btn1{
float: right;
}
.btn2{
float: left;
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  background-color: rgb(52, 158, 200)!important;
  padding: 5%;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 0;
}

.popup_inner h2 {
  margin-bottom: 4rem;
}
.DivFiles {
    position: fixed;
    width: 100%;
    /* height: 100%; */
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.9);
}

.ImgFiles{
    position: absolute;
    left: 0;
    right: 0;
    padding: 1%;
    border-radius: 2px;
    width: auto;
    max-width: -webkit-fill-available;
    height: auto;
    margin: auto;
}

.ButtonFiles {
    display: block;
    width: 50%;
    padding: 0.3%;
    margin: auto;
}
@media print {
  /* @page {
     margin: auto;
    break-before:avoid 
  } */
}


.InfpCompoPrint {
  height: 70mm;
  background: #fafafa;
  text-transform: uppercase;
  font-family: 'Asap Condensed', sans-serif;
  width: 40mm;
}

.InfpCompoPrint .infoText {
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
  margin: 0mm auto 0mm;
  height: 67mm;
  font-family: 'Georgia', sans-serif;
  text-align: right;
}
.InfpCompoPrint .infoTex:first-child{
  text-align: center;
}
.InfpCompoPrint .infoText p{
  margin: 0.5mm;
  font-family: Garamond;
  color: black;
}

.InfpCompoPrint .divImg{
  height: 3px;
  width: 13mm;
  padding-right: -22px;
  margin-right: -40px;
  padding-bottom: 26mm;
}
.InfpCompoPrint .info  {
  height: 28mm;
    width: 38mm;
    margin: auto;
    font-size: 14px;
    text-align: center;
}

.InfpCompoPrint .ID {
  font-family: system-ui;
  font-feature-settings: ;
  font-variant: proportional-width;
  margin: 15px 0 5px;
}

.InfpCompoPrint .divImg .img {
  width: auto;
  height: 40px;
  margin-top: -9px;
  transform: rotate(89deg);
}
.InfpCompoPrint .logo {
  text-align: center;
}

.InfpCompoPrint .logo h6 {
  font-size: inherit;
}
.AddHoliday .ADD{
  background-color: floralwhite;
    margin: auto;
    width: 30vw;
    padding: 1.5vw;
    text-align: center;
    border: 2px double brown;
    min-width: 330px;
}

.AddHoliday .ADD h2{
    margin-bottom: 15px;
}

.AddHoliday .ADD label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  color: black; 
}


.AddHoliday .PartitionTime{
  background-color: bisque;
  margin: 20px auto 0;
  width: 30vw;
  padding: 1.5vw 1.5vw 4vw;
  text-align: center; 
  border: 2px double brown;
  min-width: 330px;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.AddHoliday .PartitionTime .Buttons {
  width: 324px;
  display: flex;
  justify-content: space-around;
  margin-top: 35px;
}

.AddHoliday .PartitionTime p {
  margin-bottom: 3px;
}
/* .ststusHolidays #AutocompleteCity input { color: antiquewhite!important; }
.AddHoliday .ststusHolidays{
    background-color: rgb(22,74,104);
    margin: 20px auto 0;
    width: 30vw;
    padding: 1.5vw;
    text-align: center; 
    border: 2px double brown;
    min-width: 330px;
}

.AddHoliday .ststusHolidays svg.MuiSvgIcon-root.MuiSelect-icon{
right: 30px;
} */


/* .table{
    width: max-content;
    margin: auto;
  }
  .table{
    background-color: lightgrey;
  }

    
    .table {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      border: 3px solid #ddd;
    }
  
    .table {
      display: block;
    }
  
    .table tr th {
      position: sticky;
      top: 0;
    }
  
    .table td, .table th {
      border: 1px solid #ddd;
      padding: 5px;
    }
    
    .table tr:nth-child(even){background-color: #f2f2f2;}
    
    .table tr:hover {background-color: #ddd;}
    
    .table th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      background-color: gray;
      color: white;
    } */

/* 
.AddHoliday    .PackageNotCollected{
  background-color:rgb(22,74,104);
  margin: 20px auto 0;
  width: 30vw;
  padding: 0.2vw;
  text-align: center; 
  border: 2px double rgb(243, 171, 17);
  min-width: 330px;
  min-height: 360px;
}
    .AddHoliday    .ststusHolidaysfamley{
      background-color: rgb(43,150,211);
      margin: 20px auto 0;
      width: 30vw;
      padding: 0.2vw;
      text-align: center; 
      border: 2px double rgb(42, 81, 165);
      min-width: 330px;
      min-height: 360px;

  }

  .AddHoliday .MuiInputLabel-formControl {
    right: 0!important;
  color:whitesmoke ;
} */
/* 
.AddHoliday .persons {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  display: block;
  max-height: 600px;
  overflow: auto;
  margin: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: fit-content;
  max-height: 250px;
  max-width: 331px;
}


.AddHoliday #table {
  float: none;
  overflow: auto;
    margin: auto;
} */
/* .ADD{
    background-color: rgb(43,150,211);
    margin: auto;
    width: 30vw;
    padding: 1.5vw;
    text-align: center;
    border: 2px double brown;
    min-width: 330px;
}

.ADD h2{
    margin-bottom: 15px;
}
.ADD label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  color: black; 
} */
/* .ststusHolidays #AutocompleteCity input { color: antiquewhite!important; } */
.HolidaySegmentation .ststusHolidays{
    background-color: blanchedalmond;
    margin: 20px auto 0;
    width: 30vw;
    padding: 1.5vw;
    text-align: center; 
    border: 2px double brown;
    min-width: 330px;
}

.HolidaySegmentation .ststusHolidays svg.MuiSvgIcon-root.MuiSelect-icon{
right: 30px;
}


.table{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: auto;
  }
  .table{
    background-color: lightgrey;
  }

    
    .table {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      border: 3px solid #ddd;
    }
  
    .table {
      display: block;
    }
  
    .table tr th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
  
    .table td, .table th {
      border: 1px solid #ddd;
      padding: 5px;
    }
    
    .table tr:nth-child(even){background-color: #f2f2f2;}
    
    .table tr:hover {background-color: #ddd;}
    
    .table th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      background-color: gray;
      color: white;
    }


.HolidaySegmentation .PackageNotCollected1{
  background-color:blanchedalmond;
  margin: 20px auto 0;
  width: 30vw;
  padding: 0.2vw;
  text-align: center; 
  border: 2px double rgb(243, 171, 17);
  min-width: 330px;
  height: 515px;
}

.HolidaySegmentation .PackageNotCollected2{
  background-color:peachpuff;
  margin: 20px auto 0;
  width: 30vw;
  padding: 0.2vw;
  text-align: center; 
  border: 2px double rgb(243, 171, 17);
  min-width: 330px;
  height: 515px;
}

    .HolidaySegmentation .ststusHolidaysfamley{
      background-color: peachpuff;
      margin: 20px auto 0;
      width: 30vw;
      padding: 0.2vw;
      text-align: center; 
      border: 2px double rgb(42, 81, 165);
      min-width: 330px;
      height: 450px;

  }

  

  /* .HolidaySegmentation .MuiInputLabel-formControl {
    right: 0!important;
  color:whitesmoke ;
} */

.HolidaySegmentation .persons {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  display: block;
  max-height: 600px;
  overflow: auto;
  margin: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-height: 250px;
  max-width: 331px;
}


.HolidaySegmentation #tableoverflow {
  overflow: auto!important;
  max-height: 360px;
  max-width: 325px;
}
.PackageDistribution{
    margin: auto;
    width: 30vw;
    padding: 1.5vw;
    text-align: center;
    border: 2px double brown;
    min-width: 330px;
}

.PackageDistribution h2{
    margin-bottom: 15px;
}

.PackageDistribution .MuiInputLabel-formControl {
    right: 0!important;
}
.Login {
	margin:0;
	color:#6a6f8c;
	background:#c8c8c8;
    font:600 16px/18px 'Open Sans',sans-serif;
	text-align: center;
	position: relative;
}
.Login *,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}

.button{
    margin-top: 10px;
}
/* .Login .login-wrap{
	width: 100%;
    margin: auto;
    max-width: 525px;
    min-height: 670px;
    position: relative;
    background: url(https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png) ;
    background-repeat: repeat;
    background-size: contain;
    box-shadow: 0 12px 15px 0 rgba(0,0,0,.24), 
} */
.Login .login-wrap{
	width: 100%;
    margin: auto;
    max-width: 33rem;
    min-height: 100vh;
    position: relative;
	position:relative;
    background-image: url('/yadByad.png');
	/* background:url(https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png) ; */
	background-repeat: repeat;
    background-size: 138px 104px;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.Login .login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding: 9rem 5rem 4rem 5rem;
	background:rgba(40,57,101,.9);
}
.Login .login-html .sign-in-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	transform:rotateY(180deg);
	-webkit-backface-visibility:hidden;
	        backface-visibility:hidden;
	transition:all .4s linear;
}
.Login .login-html .sign-in,
.Login .login-form .groupLogin .check{
	display:none;
}
.Login .login-html .tab,
.Login .login-form .groupLogin .label,
.Login .login-form .groupLogin .button{
	text-transform:uppercase;
}
.Login .login-html .tab{
    font-size: 35px;
    margin-right: 15px;
    padding-bottom: 55px;
    margin: 0 0px 24px 0;
    display: inline-block;
}
.Login .login-html .sign-in:checked + .tab{
	color:#fff;
	border-color:#1161ee;
}
.Login .login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.Login .login-form .groupLogin{
	margin-bottom:15px;
}
.Login .login-form .groupLogin .label,
.Login .login-form .groupLogin .input,
.Login .login-form .groupLogin .button{
	width:100%;
	color:#fff;
	display:block;
}
.Login .login-form .groupLogin .input,
.Login .login-form .groupLogin .button{
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:rgba(255,255,255,.1);
}
.Login .login-form .groupLogin input[data-type="password"]{
	/* text-security:circle; */
	-webkit-text-security:circle;
}
.Login .login-form .groupLogin .label{
	color:#aaa;
	font-size:12px;
}
.Login .login-form .groupLogin .button{
	background:#1161ee;
}
.Login .login-form .groupLogin label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.Login .login-form .groupLogin label .icon:before,
.Login .login-form .groupLogin label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.Login .login-form .groupLogin label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.Login .login-form .groupLogin label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.Login .login-form .groupLogin .check:checked + label{
	color:#fff;
}
.Login .login-form .groupLogin .check:checked + label .icon{
	background:#1161ee;
}
.Login .login-form .groupLogin .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.Login .login-form .groupLogin .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.Login .login-html  .sign-in-htm{
	transform:rotate(0);
}


.Login .hr{
	height:2px;
	margin:60px 0 50px 0;
	background:rgba(255,255,255,.2);
}
.Login .foot-lnk{
	text-align:center;
}

/* image {
	background-image: url("/w3images/photographer.jpg");
	background-color: #cccccc;
	height: 500px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
  } */

  /* @font-face {
	font-family: 'password';
	font-style: normal;
	font-weight: 400;
	src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
  }
  
  .key {
	font-family: 'password';  
  } */
.Register {
	margin:0;
	color:#6a6f8c;
	background:#c8c8c8;
    font:600 16px/18px 'Open Sans',sans-serif;
    text-align: center;
}
.Register *,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}

.button{
    margin-top: 30px;
}
.Register .login-wrap{
	width:100%;
	margin:auto;
	max-width:525px;
	min-height: 100vh;
	/* min-height:670px; */
	position:relative;
    background-image: url('/yadByad.png');
	background-repeat: repeat;
    background-size: 138px 104px;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.Register .login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding:60px 70px 50px 70px;
	background:rgba(40,57,101,.9);
}
.Register .login-html .sign-in-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	transform:rotateY(180deg);
	-webkit-backface-visibility:hidden;
	        backface-visibility:hidden;
	transition:all .4s linear;
}
.Register .login-html .sign-in,
.Register .login-form .groupLogin .check{
	display:none;
}
.Register .login-html .tab,
.Register .login-form .groupLogin .label,
.Register .login-form .groupLogin .button{
	text-transform:uppercase;
}
.Register .login-html .tab{
	font-size:35px;
	margin-right:15px;
	padding-bottom:5px;
	margin:0 0px 24px 0;
	display:inline-block;
}
.Register .login-html .sign-in:checked + .tab{
	color:#fff;
	border-color:#1161ee;
}
.Register .login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.Register .login-form .groupLogin{
	margin-bottom:15px;
}
.Register .login-form .groupLogin .label,
.Register .login-form .groupLogin .input,
.Register .login-form .groupLogin .button{
	width:100%;
	color:#fff;
	display:block;
}
.Register .login-form .groupLogin .input,
.Register .login-form .groupLogin .button{
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:rgba(255,255,255,.1);
}
.Register .login-form .groupLogin input[data-type="password"]{
	/* text-security:circle; */
	-webkit-text-security:circle;
}
.Register .login-form .groupLogin .label{
	color:#aaa;
	font-size:12px;
}
.Register .login-form .groupLogin .button{
	background:#1161ee;
}
.Register .login-form .groupLogin label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.Register .login-form .groupLogin label .icon:before,
.Register .login-form .groupLogin label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.Register .login-form .groupLogin label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.Register .login-form .groupLogin label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.Register .login-form .groupLogin .check:checked + label{
	color:#fff;
}
.Register .login-form .groupLogin .check:checked + label .icon{
	background:#1161ee;
}
.Register .login-form .groupLogin .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.Register .login-form .groupLogin .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.Register .login-html  .sign-in-htm{
	transform:rotate(0);
}


.Register .hr{
	height:2px;
	margin:60px 0 50px 0;
	background:rgba(255,255,255,.2);
}
.Register .foot-lnk{
	text-align:center;
}
.Register .select {
	border: none;
	padding: 11px 18px;
	border-radius: 25px;
	background: rgba(255,255,255,.1);
}

.Register .MuiInputLabel-formControl{
    top: -3px;
    left: -46px;
    right: 0;
}

/* .Register .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input{ */
	/* text-align: center;
    padding-right: 5px;
    color: white; */
/* } */
.Register label + .MuiInput-formControl{
	margin-top: 0;margin-right: 9px;
}
.Register label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated{
/* text-align: center; */
/* left: 172px; */
color: white;
}

/* .Register label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled
{
	/* transform-origin: top center;
	top: -15px; */
/* } */ 
.Register input{
	text-align: center;
}
.Backup {
    max-width: 25%;
    margin: auto;
    text-align: center;
    padding-top: 25%;
}
.GetTicket {
    width: 100%;
    min-height: 100vh;
    margin: auto;
    z-index: 9;
    text-align: center;
    position: absolute;
    background-color: #e5e5f7;
    /* opacity: 0.8; */
    background-image:  linear-gradient(30deg, #959af1 12%, transparent 12.5%, transparent 87%, #959af1 87.5%, #959af1), linear-gradient(150deg, #959af1 12%, transparent 12.5%, transparent 87%, #959af1 87.5%, #959af1), linear-gradient(30deg, #959af1 12%, transparent 12.5%, transparent 87%, #959af1 87.5%, #959af1), linear-gradient(150deg, #959af1 12%, transparent 12.5%, transparent 87%, #959af1 87.5%, #959af1), linear-gradient(60deg, #959af177 25%, transparent 25.5%, transparent 75%, #959af177 75%, #959af177), linear-gradient(60deg, #959af177 25%, transparent 25.5%, transparent 75%, #959af177 75%, #959af177);
    background-size: 8px 14px;
    background-position: 0 0, 0 0, 4px 7px, 4px 7px, 0 0, 4px 7px;
}

.GetTicket .main {
    position: fixed;
    top: 25%;
    width: 350px;
    background-color: white;
    left: 0;
    right: 0;
    margin: auto;
    padding: 20px;
}

.GetTicket input {
    background-color: white;
    text-align: center;
    color: black;
    width: auto;
    border: 1px solid #757575;
}

.GetTicket input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

 /* .Fullscreen{
    left: -175px;
    position: fixed;
    width: 186px;
}

 .Fullscreen:hover{
    left: 0px;
} */

  .animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: textclip 2s linear infinite;
          animation: textclip 2s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@-webkit-keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.Actions {
    width: 100%;
    min-height: 100vh;
    margin: auto;
    z-index: 9;
    text-align: center;
    position: absolute;
    background-color: #e5e5f7;
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 9px ), repeating-linear-gradient( #9fe3fc55, #9fe3fc );
}

.Actions .con {
    width: 65%;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: space-evenly;
    grid-gap: 50px;
    gap: 50px;
    top: 35vh;
}


.Actions .but {
    width: 40%;
    height: 4rem;
    box-shadow: 0px 10px 14px -7px #276873;
	background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
	background-color:#599bb3;
	border-radius:8px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:20px;
	font-weight:bold;
	padding:20px 32px;
	text-decoration:none;
	text-shadow:0px 1px 0px #3d768a;
    text-align: center;
}
.Actions .but:hover {
	background:linear-gradient(to bottom, #408c99 5%, #599bb3 100%);
	background-color:#408c99;
}
.Actions .but:active {
	position:relative;
	top:1px;
}

.butS  {
    z-index: 2;
    width: 314px;
    display: block;
    outline: 0;
    border: none;
    margin-top: 150px;
    box-shadow: none;
    cursor: pointer;
    padding: 9px 22px;
    font-size: 22px;
    height: 50px;
    font-weight: 400;
    position: absolute;
    right: 0;
    left: 0;
    top: 200px;
    margin: auto ;
    color: #fff;
    text-align: center;
    line-height: normal;
    background: linear-gradient(90deg,#5522fa 0,#0074e4 100%);
    border-radius: 50px;
    transition: color .2s ease,background-color .2s ease,box-shadow .2s ease;
}
