/* .App { margin-top: 25px;
  text-align: center;
}
.App a{margin-left: 15px; 
 padding-top:7px;
 padding-bottom:7px;
 padding-left:7px ;
 padding-right:7px ;
background-color: limegreen;
width: 150px;
border-color: rgb(34, 235, 34);
box-shadow: 2px 2px cadetblue;
text-decoration: none;
box-sizing: border-box;
color: darkslategray;
} 
.App a:hover{
  box-shadow: 5px 5px cadetblue;
  background-color: rgb(255, 251, 0);
}
.App a:active{
  background-color:orangered;
  box-shadow: none;
} */





* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
/* font {display: none;} */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(240 240 240)!important;
}

input {
  background-color: rgb(240 240 240);
}
.topnavAPP , .topnavAPPLogout{
  overflow: hidden;
  background-color: cadetblue;
  z-index: 1;  position: fixed;
  margin-bottom: 5px;
}
.topnavAPP{
  width: 60px;
  height: 60px;
  border-radius: 0 0 0 100px;
  -moz-border-radius: 0 0 0 100px;
  -webkit-border-radius: 0 0 0 100px;
}
.topnavAPPLogout {left: 0;
  width: 60px;
  height: 60px;
  /* background: red; */
  border-radius: 0 0 100px 0;
  -moz-border-radius: 0 0 100px 0;
  -webkit-border-radius: 0 0 100px 0;
}

 .topnavAPPLogout a{
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 17px 28px;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.topnavAPP a {
  display: block;
  color: #f2f2f2;
  padding: 19px 14px;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.topnavAPP :hover , .topnavAPPLogout :hover{
  background-color: seagreen;
  color: black; 
}

#user{
  float: right;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.content {
  background-color: #ddd;
  padding: 10px;
 text-align: right;

}

.footer {
  background-color: #f1f1f1;
  padding: 10px;
  text-align: right;
}
.dov{
  width: fit-content;
  margin: auto;
}

.App .Fullscreen {
  left: -175px;
  position: fixed;
  width: 186px;
  z-index: 10;
  display: flex;
  flex-direction: row-reverse;
}

.App .Fullscreen:hover{
  left: 0px;
} 