.Login {
	margin:0;
	color:#6a6f8c;
	background:#c8c8c8;
    font:600 16px/18px 'Open Sans',sans-serif;
	text-align: center;
	position: relative;
}
.Login *,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}

.button{
    margin-top: 10px;
}
/* .Login .login-wrap{
	width: 100%;
    margin: auto;
    max-width: 525px;
    min-height: 670px;
    position: relative;
    background: url(https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png) ;
    background-repeat: repeat;
    background-size: contain;
    box-shadow: 0 12px 15px 0 rgba(0,0,0,.24), 
} */
.Login .login-wrap{
	width: 100%;
    margin: auto;
    max-width: 33rem;
    min-height: 100vh;
    position: relative;
	position:relative;
    background-image: url('/yadByad.png');
	/* background:url(https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png) ; */
	background-repeat: repeat;
    background-size: 138px 104px;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.Login .login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding: 9rem 5rem 4rem 5rem;
	background:rgba(40,57,101,.9);
}
.Login .login-html .sign-in-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	transform:rotateY(180deg);
	backface-visibility:hidden;
	transition:all .4s linear;
}
.Login .login-html .sign-in,
.Login .login-form .groupLogin .check{
	display:none;
}
.Login .login-html .tab,
.Login .login-form .groupLogin .label,
.Login .login-form .groupLogin .button{
	text-transform:uppercase;
}
.Login .login-html .tab{
    font-size: 35px;
    margin-right: 15px;
    padding-bottom: 55px;
    margin: 0 0px 24px 0;
    display: inline-block;
}
.Login .login-html .sign-in:checked + .tab{
	color:#fff;
	border-color:#1161ee;
}
.Login .login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.Login .login-form .groupLogin{
	margin-bottom:15px;
}
.Login .login-form .groupLogin .label,
.Login .login-form .groupLogin .input,
.Login .login-form .groupLogin .button{
	width:100%;
	color:#fff;
	display:block;
}
.Login .login-form .groupLogin .input,
.Login .login-form .groupLogin .button{
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:rgba(255,255,255,.1);
}
.Login .login-form .groupLogin input[data-type="password"]{
	/* text-security:circle; */
	-webkit-text-security:circle;
}
.Login .login-form .groupLogin .label{
	color:#aaa;
	font-size:12px;
}
.Login .login-form .groupLogin .button{
	background:#1161ee;
}
.Login .login-form .groupLogin label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.Login .login-form .groupLogin label .icon:before,
.Login .login-form .groupLogin label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.Login .login-form .groupLogin label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.Login .login-form .groupLogin label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.Login .login-form .groupLogin .check:checked + label{
	color:#fff;
}
.Login .login-form .groupLogin .check:checked + label .icon{
	background:#1161ee;
}
.Login .login-form .groupLogin .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.Login .login-form .groupLogin .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.Login .login-html  .sign-in-htm{
	transform:rotate(0);
}


.Login .hr{
	height:2px;
	margin:60px 0 50px 0;
	background:rgba(255,255,255,.2);
}
.Login .foot-lnk{
	text-align:center;
}

/* image {
	background-image: url("/w3images/photographer.jpg");
	background-color: #cccccc;
	height: 500px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
  } */

  /* @font-face {
	font-family: 'password';
	font-style: normal;
	font-weight: 400;
	src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
  }
  
  .key {
	font-family: 'password';  
  } */