.GetTicket {
    width: 100%;
    min-height: 100vh;
    margin: auto;
    z-index: 9;
    text-align: center;
    position: absolute;
    background-color: #e5e5f7;
    /* opacity: 0.8; */
    background-image:  linear-gradient(30deg, #959af1 12%, transparent 12.5%, transparent 87%, #959af1 87.5%, #959af1), linear-gradient(150deg, #959af1 12%, transparent 12.5%, transparent 87%, #959af1 87.5%, #959af1), linear-gradient(30deg, #959af1 12%, transparent 12.5%, transparent 87%, #959af1 87.5%, #959af1), linear-gradient(150deg, #959af1 12%, transparent 12.5%, transparent 87%, #959af1 87.5%, #959af1), linear-gradient(60deg, #959af177 25%, transparent 25.5%, transparent 75%, #959af177 75%, #959af177), linear-gradient(60deg, #959af177 25%, transparent 25.5%, transparent 75%, #959af177 75%, #959af177);
    background-size: 8px 14px;
    background-position: 0 0, 0 0, 4px 7px, 4px 7px, 0 0, 4px 7px;
}

.GetTicket .main {
    position: fixed;
    top: 25%;
    width: 350px;
    background-color: white;
    left: 0;
    right: 0;
    margin: auto;
    padding: 20px;
}

.GetTicket input {
    background-color: white;
    text-align: center;
    color: black;
    width: auto;
    border: 1px solid #757575;
}

.GetTicket input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

 /* .Fullscreen{
    left: -175px;
    position: fixed;
    width: 186px;
}

 .Fullscreen:hover{
    left: 0px;
} */

  .animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}