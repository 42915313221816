.butS  {
    z-index: 2;
    width: 314px;
    display: block;
    outline: 0;
    border: none;
    margin-top: 150px;
    box-shadow: none;
    cursor: pointer;
    padding: 9px 22px;
    font-size: 22px;
    height: 50px;
    font-weight: 400;
    position: absolute;
    right: 0;
    left: 0;
    top: 200px;
    margin: auto ;
    color: #fff;
    text-align: center;
    line-height: normal;
    background: linear-gradient(90deg,#5522fa 0,#0074e4 100%);
    border-radius: 50px;
    transition: color .2s ease,background-color .2s ease,box-shadow .2s ease;
}