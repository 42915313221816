/* body{background-image: url('https://cdn.pixabay.com/photo/2015/12/16/16/18/child-1096177__340.png'); */
/* background-size: 300px;} */

.Home .imgbackground{
    /* background-image:url(process.env.PUBLIC_URL + '../public/yadByad.png ') */
    background-image: url('/yadByad.png');
    background-size: cover;
    background-size: 69px;
    width: -webkit-fill-available;
    position: absolute;
    height: -webkit-fill-available;
    opacity: 0.30;
}

.Home .topHome {
    right: 0;    
    left: 0; 
    color: white;
    max-width: 15rem;
    position: fixed;
    height: 4rem;
    background-color: darkblue;
    border-radius: 24px;
    margin: auto;
    top: 0;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;   
}
/* .Home .topimgbackground {
    right: 0;
    left: 0;
    width: 99rem;
    max-width: 80%;
    position: fixed;
    height: 70px;
    margin: auto;
    opacity: 0.30;
    background-image: url('/yadByad.png');
    background-image: url(https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png);
    background-size: cover;
    background-position: unset;
    background-size: 69px;
    top: 0;
} */
/* .Home:not(#buttons)  {
    opacity: 0.60;
} */
    /* position:absolute ; */
    /* background-color: burlywood; */
     /* height: 100%;width: 75vw; */
     /* left: 0; background-image: url("https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png");} */
.Home .buttons {
    /* position: relative; */
    margin: auto;
    width: 22rem;
    padding-top: 110px;
    /* margin: auto; */
    /* max-width: 100%; */
    /* height: 182px; */
}

.Home .buttonAddPerson {
    /* background-color: rgb(35, 40, 86); */
    background-color:  rgb(22,74,104);

    margin-bottom: 30px;
    width: -webkit-fill-available;
    line-height: 2.75;
    font-size: large;
    /* justify-content: right; */
 }

.Home .buttonPersons {
    background-color:  rgb(43,150,211);
    /* background-color:  rgb(11,37,52); */
    margin-bottom: 30px;
    width: -webkit-fill-available;
    line-height: 2.75;
    font-size: large;

}

.Home .topnavAPP {
    display: none;
}