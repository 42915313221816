.Actions {
    width: 100%;
    min-height: 100vh;
    margin: auto;
    z-index: 9;
    text-align: center;
    position: absolute;
    background-color: #e5e5f7;
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 9px ), repeating-linear-gradient( #9fe3fc55, #9fe3fc );
}

.Actions .con {
    width: 65%;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: space-evenly;
    gap: 50px;
    top: 35vh;
}


.Actions .but {
    width: 40%;
    height: 4rem;
    box-shadow: 0px 10px 14px -7px #276873;
	background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
	background-color:#599bb3;
	border-radius:8px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:20px;
	font-weight:bold;
	padding:20px 32px;
	text-decoration:none;
	text-shadow:0px 1px 0px #3d768a;
    text-align: center;
}
.Actions .but:hover {
	background:linear-gradient(to bottom, #408c99 5%, #599bb3 100%);
	background-color:#408c99;
}
.Actions .but:active {
	position:relative;
	top:1px;
}
