#Persons{
  width: 94%;
  margin: auto;
}
 /* .Persons button:hover i{
    font-size: x-large;
 } */
#title {
    text-align: center;
    font-family: arial, sans-serif;
  }
.Persons .center {
  display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
}
  
.Persons .styleButton {
display: none;
}

.Persons .MuiFormControl-root{
  height: 55px;
}
.Persons .Little {
  display: none;
  }

@media screen and (max-width: 992px) {
  .Persons .BigS {
  display: none;
  }
  .Persons .Little {
    display: block;
    }
  .Persons .styleButton {
    display: -webkit-inline-box;
    margin-right: 25px;
  }
  .Persons .top {
    padding: 85px 0 15px;
  }
  .Persons .DivSearch {
    flex-wrap: wrap;
    /* width: 64%; */
    /* width: 100%; */
    margin: auto;
  }
  .Persons .margin1 {
    flex-wrap: wrap;
    height: auto!important;
    padding-bottom: 20px!important;
    justify-content: space-evenly!important;
  }
  .Persons .margin2 {
    flex-wrap: wrap;
    height: auto!important;
    padding-bottom: 20px!important;
    justify-content: space-evenly!important;
  }
  .Persons .checkedDiv {
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
  }
  .Persons .group {
    margin-top: 15px;
  }


}
  
  #persons {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    display: block;
    max-height: 600px;
    overflow: auto;
    margin: auto;
    width: max-content;
  }

  #persons tr th {
    position: sticky;
    top: 0;
  }

  #persons td, #persons th {
    border: 1px solid #ddd;
    padding: 4px;
  }
  
  #persons tr:nth-child(even){background-color: darkseagreen;}
  
  #Excel{
    padding: 3px 10px;
    font-size: 0.8125rem;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
margin-right: 20px;
  }
  #persons tr:hover {background-color: aqua;}
  
  #persons th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: darkgray;
    color: white;

  }

  .Persons .margin1{
    /* margin-bottom: 5px; */
    height: 51px;
    padding-bottom: 8px;
    border-bottom: grey 1px solid;
    background-color: #f2f2f2;
    /* padding-left: 3px; */
    /* padding-right: 7px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Persons   .margin1 input{
    background-color: #f2f2f2;
  }
  .Persons  .margin2{
    /* padding-top: 8px; */
    height: 51px;
    padding-bottom: 8px;
    border-bottom: grey 1px solid;
    background-color: #e7eaed;
    /* padding-left: 3px; */
    /* padding-right: 7px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Persons  .margin2 input {
    background-color: #e7eaed;
  }
  .Persons   .marginchecked{
    height: auto;
    background-color: #f2f2f2;
}

.Persons .checkedDiv {
  padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


  .Persons #AutocompleteCity input{
    background-color: transparent;
  }

.Persons .DateOfUpdate input{
  background-color: transparent;
}
.Persons #table{
    float: left;
    min-width: 64%;
    overflow: auto;    
    margin-top: 20px;
  }

  .Persons .tbody{
    height: 680px;
    overflow: auto;

  }

  .Persons .DivSearch{
    /* width: 34%;
    min-width: 471px;
    display: inline-block;
    border: 2px solid rgb(231, 228, 228);
    outline: rgb(146, 144, 144) solid 1px;
    outline-offset: -17px;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #f2f2f2; */

        /* height: 597px; */
    /* width: 39%; */
    /* min-width: 471px; */
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: center;
    /* justify-content: center; */
    /* margin-top: 20px; */
    /* border: 2px solid rgb(231, 228, 228); */
    outline: rgb(146, 144, 144) solid 1px;
    /* outline-offset: -17px; */
    text-align: center;
    /* padding-right: 20px; */
    /* padding-left: 20px; */
    /* background-color: #f2f2f2;*/
   } 

  .Persons .top{
    padding: 5px 0 15px;
    display: flex;
    justify-content: space-evenly;
}
  
.Persons  .groupPersons{
    position: relative;
    /* margin-bottom: -55px; */
    display: inline-block;
    /* margin: 5px; */
    /* margin-left: 3px;
    margin-right: 4px; */
  }

  .Persons .group {
    position: relative;
    /* margin-bottom: -55px; */
    display: inline-block;
    /* margin: 5px; */
    /* margin-left: 5px; */
    margin-right: 3px;
    width: 145px;
  }

